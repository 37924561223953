let resumeData = {
  "imagebaseurl": "",
  "name": "Mike Stanley",
  "role": "MERN Stack Developer",
  "linkedinId": "mrstan",
  "skypeid": "Your skypeid",
  "roleDescription": "",
  "email": "michaelrstanley87@gmail.com",
  "socialLinks": [
    {
      "name": "linkedin",
      "url": "https://www.linkedin.com/in/mr-stan/",
      "className": "fa fa-linkedin"
    },
    {
      "name": "github",
      "url": "https://github.com/MR-Stan",
      "className": "fa fa-github"
    },
    // {
    //   "name":"skype",
    //   "url":"http://twitter.com/rbhatia46",
    //   "className":"fa fa-twitter"
    // }
  ],
  "aboutme": "I'm Mike Stanley, a full stack technologist who has a passion for building web applications from end to end. I'm comfortable utilizing all of the technologies list below, as well as many others, but I especially enjoy working with the Mongo-Express-React-Node (MERN) Technology Stack.",
  "address": "Lewisville, Texas",
  "website": "",
  "upwork": "https://www.upwork.com/o/profiles/users/~015ab23ca5da104e6a/",
  "education": [
    {
      "UniversityName": "Southern Methodist University",
      "specialization": "Full Stack Web Development",
      "MonthOfPassing": "Mar",
      "YearOfPassing": "2020",
      "Achievements": "First place in VSI Annual Hackathon (link to article)"
    },
    {
      "UniversityName": "University of North Texas",
      "specialization": "Bachelors of Science in Biology",
      "MonthOfPassing": "Dec",
      "YearOfPassing": "2014",
      "Achievements": "Minors in Chemistry and Spanish"
    }
  ],
  "work": [
    {
      "CompanyName": "Fresenius Medical Care",
      "specialization": "Quality Engineer",
      "MonthOfLeaving": "Currently",
      "YearOfLeaving": "Employed",
      "Achievements": ""
    },
    {
      "CompanyName": "Upwork",
      "specialization": "Freelance",
      "MonthOfLeaving": "Currently",
      "YearOfLeaving": "Employed",
      "Achievements": ""
    }
  ],
  "skills": [
    {
      "skillname": "jQuery"
    },
    {
      "skillname": "Reactjs"
    },
    {
      "skillname": "Nodejs"
    },
    {
      "skillname": "MongoDB"
    },
    {
      "skillname": "mySQL"
    }
  ],
  "portfolio": [
    {
      "name": "Solar Memory Game",
      "description": "A solar system themed memory game built with react.",
      "imgurl": "/solar-game.jpg"
    },
    {
      "name": "Sentinel",
      "description": "A full stack mobile app used to keep track of group members built with react native, node express, and mySQL.",
      "imgurl": "/sentinel.jpg"
    },
    {
      "name": "TollTally",
      "description": "A mobile responsive web app that predicts tolls costs on a chosen route in a given period. This application was built with jQuery, momentJS, Firebase, and various google APIs",
      "imgurl": "/tolltally.jpg"
    },
    {
      "name": "Look Out",
      "description": "A full stack mobile responsive web app that shows the user recent crime around them or in a given area. This application was built with handlebars, node express, and mongoDB",
      "imgurl": "/lookout.jpg"
    },
    {
      "name": "Technology News Scraper",
      "description": "A full stack mobile responsive web app that scrapes and displays recent technology news. This application was built with handlebars, node express, and mongoDB",
      "imgurl": "/scraper.jpg"
    }
  ],
  "testimonials": [
    {
      "description": "This is a sample testimonial",
      "name": "Some technical guy"
    },
    {
      "description": "This is a sample testimonial",
      "name": "Some technical guy"
    }
  ]
}

export default resumeData;