import React, { Component } from 'react';
export default class ContactUs extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="contact" style={{ fontWeight: "bolder", color: "white" }}>
        <div className="row section-head">
          <div className="ten columns">
            <p className="lead" style={{ fontSize: "2.4rem", textAlign: "left" }}>
              Contact Information
            </p>
          </div>
        </div>
        <div className="row">
          <aside className="eigth columns footer-widgets">
            <div className="widget" style={{ color: "whitesmoke", fontSize: "1.75rem", textAlign: "left" }}>
              <p>
                <span style={{ fontWeight: "bold" }}>
                  Email:
                </span>
                <a href={resumeData.email}>{' '}
                  {resumeData.email}
                </a>
              </p>
              <p>
                <span style={{ fontWeight: "bold" }}>
                  Github:
                     </span>
                <a href={resumeData.socialLinks[1].url}>{' '}
                  {resumeData.socialLinks[1].url}
                </a>
              </p>
              <p>
                <span style={{ fontWeight: "bold" }}>
                  LinkedIn:
                     </span>
                <a href={resumeData.socialLinks[0].url}>{' '}
                  {resumeData.socialLinks[0].url}
                </a>
              </p>
              <p>
                <span style={{ fontWeight: "bold" }}>
                  Upwork:
                </span>
                <a href={resumeData.upwork}>{' '}
                  {resumeData.upwork}
                </a>
              </p>
            </div>
          </aside>
        </div >
      </section >
    );
  }
}